
.withValidation {
  position: relative;
}
.withValidation .form-control {
  padding-right: 2.50em;
}

.withValidation .form-control:not(:last-child) {
  border-radius: 4px;
}

.withValidation .validationResult {
  position: absolute;
  top: 50%;
  right: 0.75em;
  z-index: 4;
  transform: translateY(-50%);
}


.kep-login-facebook {
  text-transform: none !important;
}

