$primary: #46c0be;
$secondary: #f08632;
@import '~bootstrap';
$grid-gutter-width: 10px !default;

$primary-100: #edf9f9;
$primary-200: #c8ecec;
$primary-300: #a3e0df;
$primary-400: #7ed3d2;
$primary-500: $primary;
$primary-600: #389a98;

$primary-over: #6DD56F;
$primary-over-100: #f0fbf1;
$primary-over-200: #d3f2d4;
$primary-over-300: #b6eab7;
$primary-over-400: #99e29a;
$primary-over-500: $primary-over;
$primary-over-600: #57aa59;


$secondary-100: #fef3eb;
$secondary-200: #fbdbc2;
$secondary-300: #f8c399;
$secondary-400: #f5aa70;
$secondary-500: $secondary;
$secondary-600: #c06b28;

$pink: #fde0f5;
$pink-100: #ffccf1;
$pink-200: #fdb1e8;
$pink-300: #fa8fdc;
$pink-400: #f86ed1;
$pink-500: #F516B4FF;
$pink-600: #772A61FF;

$blue: #bfdbfd;
$blue-100: #9fc9fd;
$blue-200: #79b2f8;
$blue-300: #4594f5;
$blue-400: #1779f1;
$blue-500: #056FEFFF;
$blue-600: #104381FF;

$purple: #f4c7ff;
$purple-100: #eeaaff;
$purple-200: #e584fd;
$purple-300: #db67f8;
$purple-400: #d13af6;
$purple-500: #981ab7;
$purple-600: #981AB7FF;

$yellow: #fdf8d6;
$yellow-100: #fdf4b7;
$yellow-200: #faed93;
$yellow-300: #f6e463;
$yellow-400: #fce43e;
$yellow-500: #f5d706;
$yellow-600: #b29c02;

$danger: #f08632;
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;




.btn-primary  {
  border: none;
  padding: 8px 33px;
  cursor: pointer;
  color: $white !important;
  display: inline-block;
  font-size: 15px;
  transition: 0.6s;
  box-shadow: 0px 7px 21px 0px rgb(0 0 0 / 12%);
  background-image: linear-gradient(to left, $primary, $primary-over, $primary);
  background-position: right;
  background-size: 200%;
}
.btn-outline-primary:hover {
  color: $white !important;
}
.btn-outline-danger:hover {
  color: $white !important;
}
body {
  font-family: 'Ubuntu', sans-serif !important;
  .food-font {
    font-family: 'Dancing Script', cursive;
    //font-size: 1.5rem;
    font-weight: 400;
  }
}
.form-control:disabled, .form-control[readonly] {
  background-color: rgba(242, 242, 242, 1) !important;
  color: hsl(0, 0%, 60%) !important;
  opacity: 1;
}

.accordion-body {
  padding: 0 1.25rem 2px !important;
}
.bg-base-recipe {
  background: #f2f2f2 !important;
}

.accordion-button:not(.collapsed) {
  color: inherit !important;
  background-color: inherit !important;
}
.accordion-button {
  color: inherit !important;
  background-color: inherit !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: inherit !important;
  outline: 0;
  //box-shadow: 0px -6px 19px 0.15rem rgb(0 0 0 / 23%) !important;
}

.width-220 {
  min-width: 220px;
  max-width: 220px;
}
//.offcanvas-filter {
//  top: 8rem !important;
//  height: 15rem !important;
//  z-index: 1000 !important;
//  background-color: rgba(white,0.7) !important;
//  .card-fixed-size {
//    min-width: 7rem !important;
//    width: 7rem !important;
//
//  }
//  .btn-close {
//    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
//  }
//}
//@media only screen and (min-width: 768px) and (max-width: 991px){
//  .offcanvas-filter {
//    top: 9.3rem !important;
//  }
//}
//@media only screen and (min-width: 991px) {
//  .offcanvas-filter {
//    top: 9.7rem !important;
//  }
//}
// https://yesyouweb.com/video-youtube-responsive/
// https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css padding 56% for 16:9
//https://css-tricks.com/fluid-width-video/
.video-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}

.video-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}


.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.white-opacity-90 {
  opacity: 90%;
  background-color: white;
}
.position-bottom {
  top: unset !important;
}

.recipe-item {
  margin: 10px 0;
  padding: 10px 0 20px;
}
.views-number {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
}
.recipe-container,
.recipe-post-container {
  padding: 30px !important;
}


.recipe-title {
  margin: 15px 0 15px 0;
}
.recipe-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}
.recipe-item .recipe-sub-title {
  color: $gray-600;
}
.recipe-instruction-number {
  -webkit-box-align: center;
  align-items: center;
  background: $primary;
  border-radius: 50%;
  color: $white;
  display: flex;
  float: left;
  font-size: 1.2rem;
  height: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  padding: 15px;
  width: 20px;
  &:hover {
    background-image: linear-gradient(to left, $primary, $primary-over);
  }
}

.recipe-instruction-number-inner {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  float: left;
  font-size: 1rem;
  height: 20px;
  -webkit-box-pack: center;
  justify-content: center;
  color: $primary;
  padding: 9px;
  width: 12px;
  background: $white;
  border: 1px solid $primary;
  margin: 0 8px 0 0;
}


.button-badge {
  position: absolute;
  color: white !important;
  top: 20px;
  right: 20px;
  padding: 1rem !important;
  z-index: 200;
  font-size: 1rem !important;
  border: 2px solid $secondary;
}
.recipe-card {
  box-shadow: 0px 2px 14px 0px #dee2e6;
}
.cursor-pointer {
  cursor: pointer;
}
.fab, .far {
  font-weight: 400 !important;
}

.calorie-badge {
  position: absolute;
  background-color: white !important;
  top: 20px;
  right: 20px;
  padding: 5px !important;
  z-index: 0;
  font-size: 1rem !important;
  color: $secondary !important;
  border: 2px solid $secondary;
  border-radius: 11rem !important;
  &.small {
    font-size: 0.81rem !important;
  }
}
.corner-badge {
  @extend .calorie-badge;
  top: 2px;
  right: 2px;
}

.fill-grey path {
  fill: lightgrey;
}

$star: #FFE500;



.recipe-card-title {
  color: $primary;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  &:hover {
    color: $primary-over;
  }
}
.recipe-info {
  color: $gray-500;
}

.badge-primary-outline {
  color: $primary !important;
  background-color: white !important;
  border: 1px solid $primary;
}

.badge-round {
  border-radius: 11rem !important;
  border-width: 2px !important;
}
.badge-secondary-outline {
  color: $secondary !important;
  background-color: white !important;
  border: 1px solid $secondary;
}
.action-bar {
  background: $gray-100;//#f086321f;
  padding: .5rem 1rem;
  position: sticky;
  top: 65px;
  z-index: 1016;
  border-bottom: 1px solid $gray-200;
  box-shadow: 0px 2px 14px 0px #dee2e6;
}
.recipe-card {
  .card-img-overlay {
    padding: 0.75rem;
  }
}
@media only screen and (max-width: 768px) {

  .action-bar {
    top: 40px !important;
  }
}
@media only screen and (max-width: 990px) {
  .action-bar {
    top: 55px;
  }
}


.green-background {
  color: white;
  background-image: linear-gradient(136.73deg, $primary-400, $primary-over-400);

}
.aubergine-background {
  color: white;
  background-image: linear-gradient(136.73deg, #7152dd,#591c59);
}
.white-background {
  color: black;
  background-color: white;
}

/////
.bg-grey {
  background: #f7f8f9
}

.testimonial {
  background-image:  url("https://firebasestorage.googleapis.com/v0/b/cuisinons-302820.appspot.com/o/egg.jpg?alt=media&token=ba61c154-d675-42b3-85f7-96623942ad9c");
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
}
.instruction-bg-blue
{
  background-color: $blue-200 !important;
  color: $blue-600;
}
.instruction-bg-primary-over
{
  background-color: $primary-over-200 !important;
  color: $primary-over-600;
}
.instruction-bg-primary
{
  background-color: $primary-200 !important;
  color: $primary-600;
}
.instruction-bg-secondary
{
  background-color: $secondary-200 !important;
  color: $secondary-600;
}
.instruction-bg-purple
{
  background-color: $purple-200 !important;
  color: $purple-600;
}
.instruction-bg-pink
{
  background-color: $pink-200 !important;
  color: $pink-600;
}
.instruction-bg-yellow
{
  background-color: $yellow-200 !important;
  color: $yellow-600;
}

.instruction-border-blue
{
  border: 2px solid $blue-400 !important;
}
.instruction-border-primary-over
{
  border: 2px solid $primary-over-400 !important;
}
.instruction-border-primary
{
  border: 2px solid $primary-400 !important;
}
.instruction-border-secondary
{
  border: 2px solid $secondary-400 !important;
}
.instruction-border-purple
{
  border: 2px solid $purple-400 !important;
}
.instruction-border-pink
{
  border: 2px solid $pink-400 !important;
}
.instruction-border-yellow
{
  border: 2px solid $yellow-400 !important;
}

// taken from https://github.com/coliff/bootstrap-print-css/blob/main/css/bootstrap-print.css
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .card {
    border: none !important;
  }
  .border-print-end {
    border-right: 1px solid #dee2e6 !important;
    padding-right: 4px !important;
  }
  html {
    font-size: 1.2rem;
  }
  @page {
    @bottom-center {
      content: "Page " counter(page) " of " counter(pages);
    }
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
}

//// HERO
.hero {
  color: $white;
  //background-image: linear-gradient(
  //                136.73deg,
  //$primary-400, $primary-over-400);
 // background-image: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5)),
 // url("https://firebasestorage.googleapis.com/v0/b/cuisinons-302820.appspot.com/o/carottes.jpeg?alt=media&token=f1e426b4-cb5e-4492-91dd-057f95e6c107");
  //url("https://firebasestorage.googleapis.com/v0/b/cuisinons-302820.appspot.com/o/buee.jpg?alt=media&token=68061974-b573-479a-abf7-6d84fdaacd75");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.text-big {
  font-size: 18px;
}

.btn-outline-secondary:hover {
  color: $white !important;
}
.history {
  color: $white;
  background-image: linear-gradient(
                  136.73deg,
                  $primary-400, $primary-over-400);
}

.chef {
  position: absolute;
  top: 26%;
  left: 70%;
  padding: 16px;
  text-align: center;
  font-family: "Dancing Script", cursive;
  font-weight: 400;
  font-size: 1.2rem;
  background: white;
  border-radius: 5px;
  border: 2px solid #f8f9fa;
  width: 25%;
  box-shadow: 6px 6px 9px 3px rgb(0 0 0 / 45%);
}
.section-title {
  margin-bottom: 35px;

  span {
    color: $secondary;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 10px;
  }

  h2 {
    line-height: 60px;
    font-weight: 700;
    font-style: italic;
  }
}
.hero-image {
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)),
  url("https://firebasestorage.googleapis.com/v0/b/cuisinons-302820.appspot.com/o/aubergine.jpg?alt=media&token=e75716c9-a2e4-4b4f-9c6d-9b31d4354476");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.photo {
  width: 100px;
  position: absolute;
  top: -77px;
  left: 165px;
}
.hero-text {
  text-align: center;
  position: absolute;
  background: white;
  border-radius: 5px;
  //opacity: 0.9;
  padding: 16px;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: $secondary;
  box-shadow: 6px 6px 9px 3px rgb(0 0 0 / 45%);
}
@media (max-width: 767px) {
  .text-big {
    font-size: 14px;
  }
  .hero-text {
    top: 25%;
    left: 50%;
  }
  .photo {
    left: 74%;
  }
  .chef {
    top: 60%;
    width: 75%;
    left: 12%;
  }
}
//// end of hero
/// service
.step-number {

  border-radius: 50%;
  width: 117px;
  height: 117px;
  padding: 8px;
  border: 2px solid #666;
  color: #666;
  text-align: center;
  font: 90px Arial, sans-serif;
}

@media (max-width: 992px) {
  .step-number {
    width: 117px;
    height: 117px;
    padding: 8px;
    border: none;
    color: #666;
    text-align: center;
    font: 70px Arial, sans-serif;
  }
}
@media only screen and (max-width: 767px) {

}
////
/*---------------------
  Team
-----------------------*/
$heading-color: #111111;
// transition
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.team {
  padding-bottom: 70px;
  background: $white;
}

.team__btn {
  text-align: right;

  .primary-btn {
    border: 2px solid #b7b7b7;
    background: transparent;
    color: $heading-color;
  }
}

.team__item {
  height: 360px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  &:hover {

    .team__item__text {
      opacity: 1;
      bottom: 0;
      visibility: visible;
    }
  }
}

.team__item__text {
  text-align: center;
  background: rgb(240, 134, 50, 0.8);
  color: $gray-600;
  padding: 30px 0;
  position: absolute;
  left: 0;
  bottom: -500px;
  width: 100%;
  visibility: hidden;
  @include transition(all, .5s);

  h6 {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  span {
    color: white;
    font-size: 14px;
    display: block;
    margin-bottom: 12px;
  }
}

.team__item__social {
  a {
    display: inline-block;
    color: white;
    font-size: 16px;
    margin-right: 14px;
  }
}

.team__member_team-1 {
  background: url(./content/overview/team/cedric.jpg) no-repeat center;
}
.team__member_team-2 {
  background: url(./content/overview/team/corinne.jpg) no-repeat center;
}
.team__member_team-3 {
  background: url(./content/overview/team/fabrice.jpg) no-repeat center;
}
.team__member_team-4 {
  background: url(./content/overview/team/sandrine.jpg) no-repeat center;
}
.team__member_team-5 {
  background: url(./content/overview/team/dessert.jpg) no-repeat center;
}
.team__member_team-6 {
  background: url(./content/overview/team/emile.jpg) no-repeat center;
}
.team__member_team-7 {
  background: url(./content/overview/team/dessert2.jpg) no-repeat center;
}
.team__member_team-8 {
  background: url(./content/overview/team/dessert3.jpg) no-repeat center;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

/// end of team

/// start for wizard


$wizard-color-neutral: $gray-300 !default;
$wizard-color-active: $primary-over !default;
$wizard-color-complete: $primary !default;
$wizard-step-width-height: 40px !default;
$wizard-step-font-size: 24px !default;
.step-indicator {
  border-collapse: separate;
  display: table;
  margin-left: 0;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  padding-left: 0;
  margin-bottom: 0;
  min-width: 10rem;

  li {
    display: table-cell;
    position: relative;
    float: none;
    padding: 0;
    width: 1%;

    &:after {
      background-color: $wizard-color-neutral;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      width: 100%;
      top: $wizard-step-width-height / 2;
    }

    &:after {
      left: 50%;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.active {
      .step {
        border-color: $wizard-color-active;
        background: $wizard-color-active;
        color: $white;
      }

      .caption {
        color: $wizard-color-active;
      }
    }

    &.complete {
      &:after {
        background-color: $wizard-color-complete;
      }

      .step {
        border-color: $wizard-color-complete;
        background: $wizard-color-complete;
        color: $white;
      }

      .caption {
        color: $wizard-color-complete;
      }
    }
  }

  .step {
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid $wizard-color-neutral;
    color: $wizard-color-neutral;
    font-size: $wizard-step-font-size;
    height: $wizard-step-width-height;
    margin: 0 auto;
    position: relative;
    width: $wizard-step-width-height;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }

  .caption {
    color: $wizard-color-neutral;
    padding: 11px 16px;
  }
}
.width99 {
  width: 99px;
}
.height400 {
  height: 400px;
}

.offcanvas-wizard {
  top: 6rem !important;
  height: 5rem !important;
  z-index: 1000 !important;
  .card-fixed-size {
    min-width: 7rem !important;
    width: 7rem !important;
    background-color: rgba(255,255,255,1);
  }
  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
  .offcanvas-wizard  {
    top: 6.38rem !important;
  }
}
@media only screen and (min-width: 991px) {
  .offcanvas-wizard {
    top: 6.9rem !important;
  }
}

.offcanvas-transparent {
  background-color: rgba(white,0.7) !important;
  height: 21vh;
  .card-fixed-size {
    min-width: 7rem !important;
    width: 7rem !important;
    background-color: rgba(255,255,255,1);
  }
  .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
  }
}

.high-z-index {
  z-index: 1090;
}


.react-datepicker__input-container {
  input {
    color: #6c757d;
    border: none;
    width: 92px;
    cursor: pointer;
  }
}


.column {
  min-width: 200px;
  margin: 1rem;
  width: 200px;
  display: flex;
  flex-direction: column;
  .title {
    padding: 8px;
  }
  .recipes-list {
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 100px;
    &.recipes-list-dragging {
      background-color: $gray-200;
      opacity: 0.5;
    }
  }

  .column-item {
    background-color: transparent;
    margin: 1rem;
    &.column-item-dragging {
      opacity: 0.5;
      color: $gray-700
    }
  }
}

.large-column {
  width: 440px;
}
/// end for wizard
