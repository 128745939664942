.bg-boucherie
{
  background-color: #ba3745 !important;
  color: white;
}
.border-boucherie
{
  border: 2px solid #922D38 !important;
}
.bg-charcuterie
{
  background-color: #fcc7c2 !important;
}
.border-charcuterie
{
  border: 2px solid #ff9c93 !important;
}
.bg-epicerie {
  background-color: #ca8b4e !important;
  color: white;
}
.border-epicerie {
  border: 2px solid #AC7743 !important;
}
.bg-boulangerie {
  background-color: #fad19d !important;
}
.border-boulangerie {
  border: 2px solid #fea347 !important;
}
.bg-cremerie
{
  background-color: #ffffbf !important;
  color: black;
}
.border-cremerie
{
  border: 2px solid #F5F562 !important;
}
.bg-placard
{
  background-color: #ffe2cf !important;
  color: black;
}
.border-placard
{
  border: 2px solid #F8BA91 !important;
}
.bg-fruitsetlegumes
{
  background-color: #9bde90 !important;
}
.border-fruitsetlegumes
{
  border: 2px solid #7A9D76 !important;
}

.bg-poissonnerie, .bg-surgele
{
  background-color: #3ca2e2 !important;
  color: white;
}
.border-poissonnerie, .border-surgele
{
  border: 2px solid #23638A !important;
}
.bg-divers, .bg-traiteur
{
  background-color: #7d69db !important;
  color: white;
}
.border-divers, .border-traiteur
{
  border: 2px solid #554798 !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -6px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 6px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 6px;
}
