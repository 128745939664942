/*---------------------
  Footer
-----------------------*/
.footer {
  padding-top: 70px;
  background-color: #121212;
  a {color: white}
}


.footer__about {
  text-align: center;
  position: relative;
  margin-bottom: 30px;

  p {
   // font-size: 14px;
    color: #a4a4a4;
    line-height: 26px;
    margin-bottom: 25px;
  }
}




