//////////////////////////////////
/// Announcement Theme Colors ////
//////////////////////////////////

// Announcement Banner Background Mixin: (#1) primary background color, (#2) accent color for floating hex gradients


@mixin animated-hex-background($primary-color: #144dc0, $accent-color: #46c0be) {
  background: linear-gradient(142deg, $primary-color 11.14%, $accent-color 70%);
  //box-shadow: 0 6px 11px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  span {
    animation: animate 18s linear infinite;
    background: linear-gradient(135deg, #8f3ff1 0%, #0fe8fc 60%);
    display: block;
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: 2;
    opacity: 0;
    clip-path: circle(50% at 50% 50%);
    //clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
    //-webkit-clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
  }

  span:nth-child(1) {
    border-radius: 50%;
    animation-delay: 0s;
    animation-duration: 15s;
    bottom: 14%;
    height: 100px;
    left: -100px;
    width: 100px;
  }

  span:nth-child(2) {
    animation-delay: 2s;
    animation-duration: 25s;
    height: 40px;
    left: 15%;
    width: 40px;
  }

  span:nth-child(3) {
    animation-delay: 0s;
    animation-duration: 20s;
    height: 20px;
    left: 20%;
    width: 20px;
  }

  span:nth-child(4) {
    animation-delay: 5s;
    animation-duration: 15s;
    height: 150px;
    left: 30%;
    width: 150px;
  }

  span:nth-child(5) {
    animation-delay: 0s;
    animation-duration: 35s;
    height: 60px;
    left: 40%;
    width: 60px;
  }

  span:nth-child(6) {
    animation-delay: 3s;
    animation-duration: 20s;
    height: 25px;
    left: 50%;
    width: 25px;
  }

  span:nth-child(7) {
    animation-delay: 0s;
    animation-duration: 18s;
    height: 80px;
    left: auto;
    right: 20%;
    width: 80px;
  }

  span:nth-child(8) {
    animation-delay: 5s;
    animation-duration: 20s;
    height: 40px;
    left: 70%;
    width: 40px;
  }

  span:nth-child(9) {
    animation-delay: 0s;
    animation-duration: 16s;
    height: 100px;
    left: 80%;
    width: 100px;
  }

  span:nth-child(10) {
    animation-delay: 2s;
    animation-duration: 20s;
    height: 150px;
    left: auto;
    right: -1%;
    width: 150px;
    bottom: 100px;
  }
}

//////////////////////////////////
/// Announcement Banner Themes ///
//////////////////////////////////

.animated-background {
  @include animated-hex-background();
}


@keyframes animate {
  0% {
    border-radius: 12%;
    opacity: 0;
    transform: translateX(200px) translateY(0) rotate(48deg);
  }

  25% {
    opacity: 0.4;
  }

  100% {
    border-radius: 10%;
    opacity: 0;
    transform: translateX(800px) translateY(-200px) rotate(200deg);
  }
}
